html,
body {
  max-width: 100vw;
  min-height: 100vh;
}

body,
#__next {
  min-height: 100vh !important;
}

html {
  overflow-x: hidden;
}

/* Provide a sane default for inline emoji */
img.slack.emoji {
  width: auto;
  height: 1em;
  display: inline-block;
}